// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

// ------------------------- CHANGES TO IONIC ELEMENTS (DO NOT CHANGE) ----------------------------

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

ion-search-select {
  min-width: 300px;
  max-width: 100%;
  width: 100%;
}

ion-list {
  background: transparent;
}

ion-popover {
  &::part(content) {
    min-width: fit-content;
  }
}

.ion-no-padding-top {
  padding-top: 0;
}

.ion-no-padding-bottom {
  padding-bottom: 0;
}

.ion-no-padding-left {
  padding-left: 0;
}

.ion-no-padding-right {
  padding-right: 0;
}

ion-text {
  font-size: 14px;
}

.ion-justify-content-center {
  display: flex;
}

ion-modal::part(content) {
  border-radius: 0.85rem;
}

.picker-wrapper.sc-ion-picker-md {
  border-radius: 0.85rem;
  margin-bottom: 20%;
  border: 2px solid var(--ion-color-step-200);
}

.ion-color-light:hover {
  --background: var(--ion-color-step-100);
  --border-radius: 0.85rem;
}

.ion-flex {
  display: flex;
}

.ion-inline-flex {
  display: inline-flex;
}

.ion-button {
  --border-radius: 0.85rem;
  --font-family: 'Poppins', sans-serif;
  --box-shadow: none;
  --font-size: 13px;
  text-transform: capitalize;
  margin: 5px;
  height: 44px;
}

.ion-small-button {
  --border-radius: 0.65rem;
  --box-shadow: none;
  --font-size: 13px;
  text-transform: capitalize;
  margin: 5px;
}

.ion-icon-button {
  --border-radius: 0.85rem;
  --box-shadow: none;
  width: 35px;
  height: 35px;
  min-height: 35px;
}

.ion-margin {
  margin: var(--ion-margin, 20px) !important;
}

.ion-margin-top {
  margin-top: var(--ion-margin, 20px) !important;
}

.ion-margin-bottom {
  margin-bottom: var(--ion-margin, 20px) !important;
}

.ion-list-item {
  font-size: 14px;
  background: var(--ion-color-step-50) !important;
  margin: 10px;
  border-radius: 0.85rem;
  width: 100%;
  transition: transform 0.2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
}

.list-md {
  padding: 0;
}

ion-text {
  font-size: 14.5px;
}

ion-title {
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0;
  margin: 0;
  font-size: 15px;
  color: var(--ion-color-primary);
}

ion-title.title {
  // font-weight: 600 !important;
  font-size: 16px !important;
}

ion-item ion-input {
  background: var(--ion-color-step-50) !important;
  border: 1px solid var(--ion-color-step-150);
  margin: 10px 0 !important;
  font-family: 'Questrial', sans-serif !important;
  border-radius: 0.85rem !important;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  height: 42px;
  min-height: 44px;
}

ion-input {
  background: var(--ion-color-step-50) !important;
  border: 1px solid var(--ion-color-step-150);
  border-radius: 0.85rem !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}

ion-item ion-textarea {
  background: var(--ion-color-step-50) !important;
  border: 1px solid var(--ion-color-step-150);
  margin: 10px 0 !important;
  font-family: 'Questrial', sans-serif !important;
  border-radius: 0.85rem !important;
  font-size: 12px;
  padding: 0 0.5rem;
  min-width: fit-content;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
}

ion-item .item-inner {
  border-style: none !important;
}

ion-list {
  background: transparent;
}

ion-item {
  --border-width: 0 !important;
  --background: var(--ion-background-color) !important;
  --background-hover: transparent !important;
  --ripple-color: transparent !important;
  --background-focused: transparent !important;
  --background: transparent !important;
}

:host(.ion-focused) .item-native::after {
  background: transparent !important;
  opacity: 0 !important;
}

ion-item.cards {
  --border-width: 0 !important;
  --background: var(--ion-background-color) !important;
  --ion-background-color: var(--ion-card-background) !important;
}

.sc-ion-input-md-h {
  padding: 0px 10px 0px 10px !important;
  font-size: 15px !important;
  letter-spacing: 0.5px !important;
  color: var(--ion-color-primary) !important;
}

.item-interactive.item-has-focus {
  --highlight-background: none !important;
}

.item-interactive.item-has-focus,
.item-interactive.ion-touched.ion-valid {
  --full-highlight-height: calc(var(0) * var(0)) !important;
  --inset-highlight-height: calc(var(0) * var(0)) !important;
}

.label-stacked.sc-ion-label-md-h,
.label-floating.sc-ion-label-md-h {
  letter-spacing: 0.5px;
  top: -15px;
  opacity: 1 !important;
}

.item-interactive.ion-invalid {
  .label-stacked.sc-ion-label-md-h {
    color: #ff5722;
  }
}

.item-interactive.item-has-focus,
.item-interactive.ion-touched.ion-invalid {
  --full-highlight-height: 0 !important;
  --inset-highlight-height: 0 !important;
  --background-hover: transparent !important;
  --background-focused: transparent !important;
  --background-focused-opacity: 0 !important;
  --background-hover-opacity: 0 !important;
  --background: transparent !important;
  --background-opacity: 0 !important;
  border-radius: 0.85rem;
}

.ion-activated {
  --background: var(--ion-card-background);
  --background-opacity: 0 !important;
  --ripple-color: transparent;
}

.no-ripple {
  --ripple-color: transparent;
  --full-highlight-height: calc(0) !important;
  --inset-highlight-height: calc(0) !important;
  --background-hover: transparent !important;
  --background-focused: transparent !important;
  --background-focused-opacity: 0 !important;
  --background-hover-opacity: 0 !important;
  --background: var(--ion-card-background) !important;
  --background-opacity: 0 !important;
  --background: var(--ion-background-color) !important;
}

ion-item ion-select {
  background: var(--ion-color-step-50);
  border: 1px solid var(--ion-color-step-150);
  margin: 10px 0;
  font-family: 'Questrial', sans-serif;
  border-radius: 0.85rem;
  min-height: 44px;
  min-width: fit-content;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  padding: 10px 15px;
}

ion-select {
  font-size: 15px !important;
  letter-spacing: 0.5px !important;
  color: var(--ion-color-primary) !important;
  border: 1px solid var(--ion-color-step-150);
}

.select-disabled {
  opacity: 1;
  color: var(--disabled-primary-color) !important;
}

.ionicon {
  stroke: var(--ion-color-step-100) !important;
  background-color: var(--ion-color-step-100) !important;
  border-radius: 10px !important;
}

.searchbar-input.sc-ion-searchbar-md {
  --background: var(--ion-color-step-50);
  --box-shadow: none;
  --border-radius: 0.85rem;
  --placeholder-color: var(--disabled-primary-color);
  --placeholder-opacity: 1;
  border: 1px solid var(--ion-color-step-150);
}

.sc-ion-modal-md-h:first-of-type {
  --box-shadow: none !important;
}

.modal-wrapper.sc-ion-modal-md {
  border-radius: 0.85rem;
  box-shadow: var(--card-box-shadow);
  border: 2px solid var(--ion-color-step-150);
}

.header-md {
  box-shadow: none;
}

.header-md::after {
  background-image: none;
}

.footer-md::before {
  background-image: none;
}

.alert-wrapper.sc-ion-alert-md {
  box-shadow: none;
  border-radius: 0.85rem !important;
  min-width: 30%;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  background: var(--ion-color-step-50);
  border: 1px solid var(--ion-color-step-150);
  font-size: 14px;
  padding: 10px 40px;
  height: 44px;
  min-height: 44px;
  border-radius: 0.85rem;
}

.sc-ion-searchbar-ios-h {
  padding: 0px;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
  top: 3px !important;
}

.item-inner {
  border-width: 0 !important;
  border-color: transparent !important;
}

ion-card-title {
  color: var(--ion-color-primary);
}

ion-segment ion-label {
  font-size: 14px;
  letter-spacing: 0.5px;
}

ion-segment {
  background: var(--ion-color-step-50);
  color: var(--ion-color-primary);
  border-radius: 0.65rem;
  height: 45px;
}

ion-skeleton-text {
  border-radius: 0.85rem;
}

ion-chip {
  // background: var(--ion-color-step-100);
  --ion-color-base-rgb: var(--ion-color-light-contrast-rgb, 45, 211, 111);
  width: fit-content;
  border-radius: 0.65rem;
  margin: 10px 2px;
}

.ion-margin {
  margin: var(--ion-margin, 20px) !important;
}

.ion-margin-top {
  margin-top: var(--ion-margin, 20px) !important;
}

.ion-margin-bottom {
  margin-bottom: var(--ion-margin, 20px) !important;
}

.sc-ion-select-popover-h ion-label.sc-ion-select-popover {
  font-size: 14px;
}

.popover-content.sc-ion-popover-ios {
  // overflow:  hidden !important;
  width: fit-content;
}

@media screen and (max-width: 400px) {
  ion-item .ionic-selectable {
    min-width: fit-content;
  }

  .ionic-selectable {
    min-width: fit-content;
  }

  ion-item ion-input {
    min-width: auto;
  }

  ion-item ion-textarea {
    min-width: auto;
  }

  ion-item ion-select {
    min-width: auto;
  }
}

button.alert-button.alert-button-confirm {
  font-weight: 600 !important;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-dark-contrast);
  border-radius: 0.65rem;
  font-size: 16px;
}

button.alert-button.alert-button-cancel {
  font-weight: 600 !important;
  border: none;
  border-radius: 0.65rem;
}

button.alert-button.alert-button-danger-confirm {
  font-weight: 600 !important;
  background-color: var(--ion-color-danger);
  color: var(--ion-background-color);
  border-radius: 0.65rem;
  font-size: 16px;
}

button.alert-button.alert-button-danger-cancel {
  font-weight: 600 !important;
  border: none;
  color: var(--ion-color-danger);
  border-radius: 0.65rem;
}

.alert-button.sc-ion-alert-ios:last-child {
  font-weight: 500 !important;
}

.alert-button-group {
  padding: 10px;
}

.alert-head.sc-ion-alert-ios {
  padding-top: 16px;
}

.alert-message.sc-ion-alert-ios {
  font-size: 14px;
  padding-top: 16px;
}
