/** -------------------------------------------- */
/** Angular Material Theme Only */
/** -------------------------------------------- */
/**  This file will be deprecated */
/** -------------------------------------------- */
/**  This file needs to be deleted once Accounts, 
     Logs and Roles will be refactored to v2 
    (these are the last modules that use material)  */
/** -------------------------------------------- */

@use 'src/theme/variables.scss' as variables;
@use '@angular/material' as mat;
@include mat.core();

$ion-primary-color: (
  50: #e5e5eb,
  100: #bebfcd,
  200: #9395ac,
  300: #676a8a,
  400: #474a71,
  500: var(--ion-color-primary),
  600: #222550,
  700: #1c1f47,
  800: #17193d,
  900: #0d0f2d,
  A100: #6a70ff,
  A200: #373fff,
  A400: #040eff,
  A700: #0009ea,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$font-family-base: 'Poppins', sans-serif;
$font-size-base: 20px;

$my-primary: mat.define-palette($ion-primary-color);
$my-accent: mat.define-palette(mat.$light-blue-palette, 200);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);

mat-row {
  background: var(--ion-background-color) !important;
  color: var(--ion-color-primary) !important;
}

mat-cell {
  color: var(--ion-color-primary) !important;
}

mat-table {
  background: var(--ion-background-color) !important;
  color: var(--ion-color-primary) !important;
}

mat-paginator {
  background: var(--ion-background-color) !important;
  color: var(--ion-color-primary) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: var(--ion-background-color) !important;
  color: var(--ion-color-primary) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background: var(--ion-background-color) !important;
  color: var(--ion-color-primary) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(170, 170, 170, 0.42);
}

.mat-slide-toggle-bar {
  background-color: rgba(8, 70, 58, 0.25);
}

.bd {
  box-shadow: 0 2px 15px 0 rgba(31, 85, 79, 0.12);
}

.mat-icon-button.mat-primary {
  color: var(--ion-color-primary) !important;
  background-color: rgba(114, 136, 130, 0.09);
}

.cdk-overlay-pane {
  background: var(--ion-background-color) !important;
  box-shadow: 0 0 30px 0 rgb(63 86 105 / 5%);
  border: none !important;
  min-width: fit-content !important;
  box-shadow: none !important;
  padding: 0 !important;
  border-radius: 0.85rem !important;
}

::ng-deep .mat-tooltip {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
}

.mat-select-search-inner {
  background: var(--ion-background-color);
  color: var(--ion-text-color);
  z-index: 1000;
}

.mat-mdc-form-field {
  min-width: 300px;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: var(--ion-background-color) !important
;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--ion-background-color) !important;
  --mdc-checkbox-unselected-icon-color: var(--ion-color-primary);
}

.mat-mdc-checkbox label {
  color: var(--ion-color-primary);
}
