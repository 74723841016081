@import url('https://fonts.googleapis.com/css?family=Rajdhani&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css?family=Questrial&display=swap');
@import 'ngx-toastr/toastr';

tooltip {
  font-size: 14px;
}

body {
  line-height: 1.5;
  text-align: left;
}

th {
  text-align: inherit;
}

ion-chip {
  min-height: unset; // Removes min-height from ion-chip (default is 32px)
}

line-turn-down-right {
  display: inline-block;
  margin: 4px;
  width: 15px;
  height: 10px;
  border-bottom-left-radius: 3px;
  border-bottom: 1px solid var(--ion-color-step-500);
  border-left: 1px solid var(--ion-color-step-500);
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.hover:hover {
  background: var(--ion-color-step-50);
}

.on-text-hover {
  cursor: pointer;
  transition: 0.15s ease-in-out;

  &:hover {
    text-decoration: underline;
    border-radius: 5px;
  }
}

.on-hover-icon {
  cursor: pointer;
  transition: 0.15s ease-in-out;
}

.disabled-line-through {
  text-decoration: line-through;
  color: var(--disabled-primary-color);
}

.eset-modal {
  --height: 20% !important;
  --box-shadow: var(--card-box-shadow) !important;
}

.fa-spin {
  -webkit-animation: fa-spin 0.7s infinite linear;
  animation: fa-spin 0.7s infinite linear;
}

.copy-text {
  cursor: pointer;
  transition: 0.15s ease-in-out;

  &:hover {
    background: var(--ion-color-step-100);
    padding: 5px;
    border-radius: 5px;
  }
}

.text-highlight {
  font-weight: 500;
  font-size: 15px;
  color: var(--ion-color-primary);
}

ion-toolbar.header {
  --background: var(--ion-color-step-10);
  padding: 10px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ion-color-primary) !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
  font-family: 'Poppins', sans-serif !important;
}

.quantity-button {
  margin: 0;
  width: 90px;
  height: 40px;
  color: var(--ion-color-primary);

  --background: var(--ion-color-step-50);
  --border-radius: 0.85rem;
  --box-shadow: none;

  ion-span.quantity {
    width: 40px;
    padding-left: 10px;
    text-align: start;
  }

  ion-span.icon {
    width: 40px;
    padding-right: 10px;
    text-align: end;
  }

  &::part(native) {
    border: 1px solid var(--ion-color-step-150);
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}

ion-modal.modal-default.show-modal ~ ion-modal.modal-default {
  --backdrop-opacity: 0.32 !important;
  --box-shadow: none;
}

.disabled-card {
  background: repeating-linear-gradient(45deg, var(--ion-color-step-50), var(--ion-color-step-50) 22px, var(--ion-color-step-0) 25px, var(--ion-color-step-0) 20px);
  box-shadow: none;
  align-items: center;
  padding: 0 !important;
  margin: 3px 0 !important;
  border-radius: 0.85rem;
  border: 2px solid var(--ion-color-step-100);
}

.main-content-wrapper {
  margin: 0px 3%;
}

.border-bottom {
  border-bottom: 2px solid var(--ion-color-step-100) !important;
}

.border-top {
  border-top: 2px solid var(--ion-color-step-100) !important;
}

.sc-ion-modal-md-h:first-of-type {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.not-found {
  background: var(--ion-color-step-100);
  border: 1px solid var(--ion-color-step-200);

  box-shadow: none;
  border-radius: 1rem;
  width: fit-content;
  padding: 2vh;
  margin: 2%;
  text-align: center;
}

.loading-class {
  --spinner-color: var(--ion-color-primary);
  --min-width: fit-content;
  --width: fit-content;
}

.notification-select-popover {
  &::part(content) {
    width: 540px;
  }
}

@media (max-width: 768px) {
  .notification-select-popover {
    &::part(content) {
      width: 340px;
    }
  }
}

.serviceNone {
  color: var(--ion-color-step-300) !important;
}

.serviceFalse {
  color: orange !important;
}

.serviceTrue {
  color: var(--ion-color-success) !important;
}

.serviceError {
  color: var(--ion-color-danger) !important;
}

.primary-color {
  color: var(--ion-color-primary) !important;
}

.secondary-color {
  color: var(--ion-color-secondary) !important;
}

.lightblue-color {
  color: var(--ion-color-lightblue) !important;
}

.green-color {
  color: var(--ion-color-green) !important;
}

.warning-color {
  color: var(--ion-color-warning) !important;
}

.warning-shade-color {
  color: var(--ion-color-warning-shade) !important;
}

.success-color {
  color: var(--ion-color-success) !important;
}

.danger-color {
  color: var(--ion-color-danger) !important;
}

.grey-color {
  color: var(--ion-color-grey) !important;
}

.info-color {
  color: var(--ion-color-secondary) !important;
}

.disabled-color {
  color: var(--disabled-primary-color) !important;
}
.disabled-background-color {
  background-color: var(--ion-color-step-150) !important;
}

.brown-color {
  color: var(--ion-color-brown) !important;
}

.medium-color {
  color: var(--ion-color-medium) !important;
}

.card-box {
  border-radius: 15px;
  border: 2px solid var(--ion-color-step-100);
  padding: clamp(10px, 3%, 20px);
  margin: clamp(10px, 3%, 20px) 0;
  width: 100%;
  // box-shadow: var(--border-box-shadow);
}

.external-link {
  color: var(--ion-color-primary);
  font-size: 13px;
  cursor: pointer;
  margin: 4px 2px;
}

.svg-inline--fa.primary {
  color: var(--ion-color-primary);
}

.svg-inline--fa.danger {
  color: var(--ion-color-danger);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

hr {
  box-sizing: border-box;
  border-bottom: 1px solid var(--ion-color-step-100) !important;
}

/* IMPORTED FOR IAAS */
.input-popover {
  &::part(content) {
    width: 400px;
    --min-width: 400px;
    border-radius: 15px;
  }
}

.confirm-action-popover {
  &::part(content) {
    width: fit-content !important;
    border-radius: 15px;
    padding: 0.5rem 1rem;
  }
}

.modal-power-option {
  &::part(content) {
    border-radius: 10px !important;
    height: 163px !important;
  }
}

.modal-wss-reconnect {
  &::part(content) {
    height: 222px !important;
  }
}

.modal-delete-option {
  &::part(content) {
    height: 209px !important;
    border: 2px solid whitesmoke;
  }
}

.modal-update-confirm-option {
  &::part(content) {
    height: fit-content !important;
    min-height: 258px;
    border: 1px solid #b7b7b7;
  }
}

.modal-console-connect {
  &::part(content) {
    height: 163px !important;
  }
}

.modal-error-option {
  &::part(content) {
    height: 180px !important;
  }
}

@media screen and (max-width: 767px) {
  .modal-delete-option {
    &::part(content) {
      margin: 0 1rem !important;
      border-radius: 10px !important;
      height: 220px !important;
    }
  }

  .modal-power-option {
    &::part(content) {
      margin: 0 1rem !important;
      border-radius: 10px !important;
    }
  }

  .modal-error-option {
    &::part(content) {
      margin: 0 1rem !important;
      border-radius: 10px !important;
    }
  }

  .mobile-center {
    margin: 5px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

/* IMPORTED FOR IAAS */

.modal-eset {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.eset-custom-alert .alert-wrapper {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  border-radius: 0.85rem !important;
  border: 3px solid var(--ion-color-step-150) !important;

  .header {
    color: var(--ion-color-primary);
  }

  .alert-button-group {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      //border: 2px solid var(--ion-color-primary);
      //border-radius: 6px;
      background-color: var(--ion-color-primary);
      margin: 10px;
      height: 42px;
      border-radius: 0.85rem;
    }

    span {
      display: flex;
      justify-content: center;
      color: white;
      size: 13px;
      text-transform: capitalize;
      font-family: 'Poppins', sans-serif;
    }
  }
}

.ion-button {
  --border-radius: 0.85rem;
  --font-family: 'Poppins', sans-serif;
  --box-shadow: none;
  --font-size: 13px;
  text-transform: capitalize;
  margin: 10px;
  height: 42px;
}

.ion-button-danger {
  --border-radius: 0.85rem;
  --font-family: 'Poppins', sans-serif;
  --box-shadow: none;
  --font-size: 13px;
  text-transform: capitalize;
  margin: 10px;
  height: 42px;
  --background: var(--ion-color-danger) !important;

  .ion-activated {
    --background: var(--ion-color-danger-shade) !important;
  }
}

.ion-small-button {
  --border-radius: 0.45rem;
  --box-shadow: none;
  --font-size: 13px;
  text-transform: capitalize;
  margin: 5px;
}

.ion-icon-button {
  --border-radius: 0.85rem;
  --box-shadow: none;
  width: 35px;
  height: 35px;
}

.cards {
  border-radius: 0.85rem;
  background-color: var(--ion-card-background) !important;
  border: 2px solid var(--ion-color-step-100);
  padding: 10px;
  margin: 5px 10px;
  box-shadow: var(--card-box-shadow);
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

.note-warning {
  color: var(--ion-color-warning);
  font-family: 'Questrial', sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 0px 0px 10px 0px;
  animation: slide-down 0.1s;
}

.note-warning::after {
  animation: slide-up 0.2s;
}

.warning-text {
  border-radius: 0.8rem;
  padding: 2px 5px;
  border: 1px solid rgba(var(--ion-color-warning-rgb), 0.5);
  background: rgba(var(--ion-color-warning-rgb), 0.05);
  color: var(--ion-color-warning-shade);
}

.success-text {
  border-radius: 0.8rem;
  padding: 2px 5px;
  border: 1px solid rgba(var(--ion-color-success-rgb), 0.5);
  background: rgba(var(--ion-color-success-rgb), 0.05);
  color: var(--ion-color-success-shade);
}

.blank-alert {
  font-family: 'Questrial', sans-serif;
  border: 1px solid black;
  font-size: 16px;
  margin: 15px 0px;
  padding: 10px;
  border-radius: 0.75rem;
  letter-spacing: 0.5px;
}

.blue-alert-color {
  color: var(--ion-color-blue);
  background: rgba(var(--ion-color-blue-rgb), 0.05);
  border: 1px solid rgba(var(--ion-color-blue-rgb), 0.4) !important;
}

.success-alert-color {
  color: var(--ion-color-success-shade);
  background: rgba(var(--ion-color-success-rgb), 0.05);
  border: 1px solid rgba(var(--ion-color-success-rgb), 0.5) !important;
}

.light-blue-alert-color {
  color: var(--ion-color-lightblue);
  background: rgba(var(--ion-color-lightblue-rgb), 0.05);
  border: 1px solid rgba(var(--ion-color-lightblue-rgb), 0.4) !important;
}

.info-alert-color {
  color: var(--ion-color-primary-shade);
  background: rgba(var(--ion-color-primary-rgb), 0.05);
  border: 1px solid rgba(var(--ion-color-primary-rgb), 0.25) !important;
}

.primary-alert-color {
  color: var(--ion-color-primary);
  background: rgba(var(--ion-color-primary-rgb), 0.1);
  border: 1px solid rgba(var(--ion-color-primary-rgb), 0.15) !important;
}

.red-alert-color {
  color: var(--ion-color-danger);
  background: rgba(var(--ion-color-danger-rgb), 0.15);
  border: 1px solid rgba(var(--ion-color-danger-rgb), 0.4) !important;
}

.yellow-alert-color {
  color: var(--ion-color-warning);
  background: rgba(var(--ion-color-warning-rgb), 0.15);
  border: 1px solid rgba(var(--ion-color-warning-rgb), 0.4) !important;
}

.gray-alert-color {
  color: var(--ion-color-medium);
  background: rgba(var(--ion-color-medium-rgb), 0.15);
  border: 1px solid rgba(var(--ion-color-medium-rgb), 0.4) !important;
}

.success-alert {
  border: 1px solid rgba(var(--ion-color-success-rgb), 0.5);
  background: rgba(var(--ion-color-success-rgb), 0.05);
  color: var(--ion-color-success-shade);
  font-family: 'Questrial', sans-serif;
  font-size: 16px;
  margin: 15px 0px;
  padding: 10px;
  border-radius: 0.75rem;
  letter-spacing: 0.5px;
}

.lightblue-alert {
  color: var(--ion-color-lightblue);
  background: rgba(var(--ion-color-lightblue-rgb), 0.05);
  border: 1px solid rgba(var(--ion-color-lightblue-rgb), 0.4);
  font-family: 'Questrial', sans-serif;
  font-size: 16px;
  margin: 15px 0px;
  padding: 10px;
  border-radius: 0.75rem;
  letter-spacing: 0.5px;
}

.blue-alert {
  color: var(--ion-color-blue);
  background: rgba(var(--ion-color-blue-rgb), 0.05);
  border: 1px solid rgba(var(--ion-color-blue-rgb), 0.4);
  font-family: 'Questrial', sans-serif;
  font-size: 16px;
  margin: 15px 0px;
  padding: 10px;
  border-radius: 0.75rem;
  letter-spacing: 0.5px;
}

.info-alert {
  color: var(--ion-color-primary-shade);
  background: rgba(var(--ion-color-primary-rgb), 0.05);
  border: 1px solid rgba(var(--ion-color-primary-rgb), 0.25);
  font-family: 'Questrial', sans-serif;
  font-size: 15px;
  margin-left: -5px;
  padding: 10px;
  border-radius: 0.75rem;
  letter-spacing: 0.5px;
}

.primary-alert {
  color: var(--ion-color-primary);
  background: rgba(var(--ion-color-primary-rgb), 0.1);
  border: 1px solid rgba(var(--ion-color-primary-rgb), 0.15);
  font-family: 'Questrial', sans-serif;
  font-size: 16px;
  margin: 15px 0px;
  padding: 15px;
  border-radius: 0.75rem;
  letter-spacing: 0.5px;
}

.warning-alert {
  color: var(--ion-color-warning-shade);
  background: rgba(var(--ion-color-warning-rgb), 0.03);
  border: 1px solid rgba(var(--ion-color-warning-rgb), 0.5);
  font-family: 'Questrial', sans-serif;
  font-size: 16px;
  margin: 15px 0px;
  padding: 15px;
  border-radius: 0.75rem;
  letter-spacing: 0.5px;
}

.error-alert {
  color: var(--ion-color-danger);
  background: rgba(var(--ion-color-danger-rgb), 0.15);
  border: 1px solid rgba(var(--ion-color-danger-rgb), 0.4);
  font-family: 'Questrial', sans-serif;
  font-size: 16px;
  margin: 15px 0px;
  padding: 15px;
  border-radius: 0.75rem;
  letter-spacing: 0.5px;
}

.active-circle {
  color: green !important;
  margin-left: 5px;
  display: inline-flex;
  align-self: center;
}

.inactive-circle {
  color: red !important;
  margin-left: 5px;
  display: inline-flex;
  align-self: center;
}

.pending-circle {
  color: orange !important;
  margin-left: 5px;
  display: inline-flex;
  align-self: center;
}

.side-modal-class {
  display: flex;
  justify-content: flex-end;

  &::part(content) {
    height: 100% !important;
    min-width: 40% !important;
    box-shadow: var(--card-box-shadow);
    border-left: 2px solid var(--ion-color-step-100);
    margin-left: 240px;
    top: 60px;
    border-radius: 0;
  }

  @media screen and (max-width: 1700px) {
    &::part(content) {
      min-width: 60% !important;
    }
  }

  @media screen and (max-width: 1300px) {
    &::part(content) {
      min-width: 75% !important;
    }
  }

  @media screen and (max-width: 1000px) {
    &::part(content) {
      min-width: 100% !important;
    }
  }
}

.half-side-modal-class {
  display: flex;
  justify-content: flex-end;

  &::part(content) {
    height: 100%;
    min-width: 20%;
    box-shadow: var(--card-box-shadow);
    border-left: 2px solid var(--ion-color-step-100);
    margin-left: 120px;
    top: 60px;
    border-radius: 0;
  }
}

.full-modal-class {
  display: flex;
  justify-content: center;

  &::part(content) {
    height: 100%;
    width: 100%;
    top: 60px;
    border-radius: 0;
  }
}

.spla-notification-modal-class {
  &::part(content) {
    height: 350px;
    border-radius: 0.85rem;
  }
}

.half-modal-class {
  display: flex;
  justify-content: center;

  &::part(content) {
    height: clamp(80%, 900px, 100%);
    width: 900px;
    top: 60px;
    border-radius: 1rem;
    box-shadow: var(--card-box-shadow);
    border-left: 2px solid var(--ion-color-step-100);
  }
}

.center-modal-small-class {
  display: flex;
  justify-content: center;
  --max-height: 300px;
  &::part(content) {
    border-radius: 1rem;
    border: 2px solid var(--ion-color-step-100);
  }
}

.center-modal-class {
  display: flex;
  justify-content: center;

  &::part(content) {
    height: 50vw;
    max-height: 50rem;
    min-width: 35vw;
    top: 60px;
    border-radius: 1rem;
    box-shadow: var(--card-box-shadow);
    border-left: 2px solid var(--ion-color-step-100);
  }

  @media screen and (max-width: 991px) {
    &::part(content) {
      height: 100vh;
      max-height: 100vh;
    }
  }
}

.exclaimer-migration-modal-class {
  display: flex;
  justify-content: center;

  &::part(content) {
    height: clamp(20%, 500px, 100%);
    width: 500px;
    top: 60px;
    border-radius: 1rem;
    box-shadow: var(--card-box-shadow);
    border-left: 2px solid var(--ion-color-step-100);
  }
}

// .half-modal-class .modal-wrapper {
//   height: clamp(80%, 900px, 100%);
//   width: 900px;
//   top: 60px;
//   border-radius: 0;
// }

.toast-class {
  color: white;
  --background: var(--ion-color-primary);
  --box-shadow: none;
}

.ion-list-item {
  font-size: 14px;
  background: var(--ion-color-step-100);
  border: 2px solid var(--ion-color-step-150);
  margin: 10px;
  border-radius: 0.85rem;
  width: 100%;
  transition: transform 0.2s;
}

.ion-list-item:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.list-md {
  padding: 0;
}

.note {
  color: var(--ion-text-note);
  font-family: 'Questrial', sans-serif;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 0;
}

.note-title {
  color: var(--ion-text-color);
  font:
    normal 15px/28px 'Poppins',
    Helvetica,
    Arial,
    Verdana,
    sans-serif;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.note-error {
  color: var(--ion-color-danger);
  font-family: 'Questrial', sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  // animation: slide-down 0.1s;
}

.toast-container .ngx-toastr {
  border-radius: 0.85rem !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.toast-container .ngx-toastr:hover {
  box-shadow: none !important;
}

.blob {
  padding: 7px;
}

.hide-popover-backdrop ion-backdrop {
  opacity: 0 !important;
  animation: none;
  background: none;
}

// A CSS Class that can be used on/in a ion-select interface options, to make the options within fit-content (makes all text readable)
.fit-content-popover .popover-wrapper div {
  width: fit-content;
}

.fit-content-popover .popover-wrapper .popover-content .popover-viewport .sc-ion-select-popover .select-interface-option ion-radio {
  margin-left: 1rem;
}

.user-icon {
  background: var(--ion-color-primary);
  // background: var(--ion-text-note);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 0.85rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  padding-top: 10px;
  text-align: center;
  margin-right: 15px;
}

.blob {
  background: black;
  border-radius: 50%;
  margin: 10px 5px;
  height: 5px;
  width: 5px;
  padding: 5px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
}

.blob.active-blob {
  background: green;
  animation: pulse 4s 0s infinite;
}

.blob.inactive-blob {
  background: red;
}

.blob.pending-blob {
  background: orange;
}

.ionic-delete-alert {
  --max-width: 365px;
}

.ionic-delete-alert {
  .alert-wrapper {
    .alert-sub-title {
      font-size: 14px !important;
      color: var(--ion-color-danger) !important;
      background: rgba(var(--ion-color-danger-rgb), 0.1) !important;
      padding: 10px !important;
      font-weight: 600 !important;
      border-radius: 0.85rem !important;
      border: 2px solid rgba(var(--ion-color-danger-rgb), 0.3) !important;
      animation: pulse-beat 0.5s;
      animation-iteration-count: 2;
    }
  }
}

.ionic-delete-alert {
  .alert-wrapper {
    padding: 10px !important;
  }
}

.ionic-delete-alert {
  .alert-wrapper {
    .alert-title {
      margin-bottom: 15px !important;
      color: var(--ion-color-danger) !important;
    }
  }
}

.ion-input-error {
  font-family: 'Questrial', sans-serif;
  font-size: 14px;
  color: var(--ion-color-danger);
  letter-spacing: 0.5px;
  margin-top: -5px;
  animation: slide-down 0.1s;
}

.mobile-end-to-center {
  display: flex;
  justify-content: flex-end;
}

.mobile-start-to-center {
  display: flex;
  justify-content: flex-start;
}

.mobile-center-to-center {
  display: flex;
  justify-content: center;
}

// Important CSS for IaaS Console. The console will break, if this is commented out 💩
.modal-confirm {
  &::part(content) {
    height: 240px !important;
    width: 400px !important;
    border-radius: 10px !important;
  }
}

.pulse-beat {
  animation: pulse-beat 0.5s;
  animation-iteration-count: 2;
}

.pulse-beat-continuos {
  animation: pulse-beat 0.5s;
  animation-iteration-count: 2;
}

.slide-down-effect {
  animation: slide-down 0.2s;
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
  animation: flickerAnimation 1.5s infinite;
}

.loading-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis-dots steps(4, end) 900ms infinite;
  animation: ellipsis-dots steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis-dots {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis-dots {
  to {
    width: 20px;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    line-height: 0px;
  }

  to {
    opacity: 1;
    line-height: 10px;
  }
}

@keyframes slide-up {
  from {
    opacity: 1;
    line-height: 10px;
  }

  to {
    opacity: 0;
    line-height: 0px;
  }
}

.pulse-beat {
  animation: pulse-beat 0.5s;
  animation-iteration-count: 2;
}

@keyframes pulse-beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.04);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 128, 0.7);
  }

  40% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 128, 0, 0);
  }

  80% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 128, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 128, 0, 0);
  }
}

@keyframes pulse-beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.04);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 991px) {
  .side-modal-class {
    &::part(content) {
      margin-left: 0;
    }
  }

  .ion-margin {
    margin: var(--ion-margin, 5px) !important;
  }

  .mobile-end-to-center {
    display: flex;
    justify-content: center;
    text-align-last: center;
  }

  .mobile-start-to-center {
    display: flex;
    justify-content: center;
    text-align-last: center;
  }
}

@media screen and (max-width: 480px) {
  .input-popover .popover-content {
    width: 93vw;
    border-radius: 15px;
  }
}

@media screen and (max-width: 400px) {
  ion-row {
    margin: 0px;
    padding: 0px;
  }

  ion-col {
    margin: 0px;
    padding: 5px 0px;
  }

  .cards {
    margin: 10px 0px;
    padding: 0px 10px;
  }

  .card-content-md,
  .card-content-ion {
    padding: 5px;
  }

  .modal-wrapper.sc-ion-modal-md {
    border-radius: 0;
  }
}

@media screen and (max-width: 550px) {
  .mobile-end-to-center {
    display: flex;
    justify-content: center;
    // margin: 3%;
    text-align-last: center;
  }

  .mobile-start-to-center {
    display: flex;
    justify-content: center;
    text-align-last: center;
  }
}

@media screen and (max-width: 1600px) {
  .main-content-wrapper {
    margin: 0px 1%;
  }
}

// target everything that uses hidden="true" like: <div hidden="true"> or <div hidden> etc.
[hidden='true'] {
  display: none !important;
}

[hidden] {
  display: none !important;
}

fa-icon[hidden] {
  display: unset !important;
}

ion-item {
  text-wrap: nowrap;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input {
  cursor: inherit;
}

/** Only displyed when bodys .dark class is set

 */

.dark-only {
  display: none;
}

body.dark .light-only {
  display: none;
}

body.dark .dark-only {
  display: unset;
}

.create-transport-modal {
  --width: 1080px;
}
