// *
//  * Dark Colors
//  * -------------------------------------------
//  */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/** Ionic CSS Variables **/
:root {
  --ion-padding-start: 10px !important;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-card-background: #ffffff;
  --ion-text-note: #858692;
  --ion-text-color: #6f6f77;
  --ion-color-input: #575664;

  --ion-color-step-0: #ffffff;
  --ion-color-step-10: #fbfbfb;
  --ion-color-step-50: #f6f6f9;
  --ion-color-step-100: #f2f2f5;
  --ion-color-step-150: #ececef;
  --ion-color-step-200: #dedee4;
  --ion-color-step-300: #c9c9cf;
  --ion-color-step-500: #a9a9ae;

  --background-focused: #ffffff00;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  --background-hover: transparent !important;
  --background-opacity: 0;

  --primary-alert-background: #dfdfe8;

  // --disabled-primary-color: #9e9eb9;
  --disabled-primary-color: #babac5;

  // --card-box-shadow: 0 0 30px 0 rgb(78 80 101 / 10%);
  --card-box-shadow: 0px 8px 20px rgb(0 0 0 / 6%);
  // --border-box-shadow: 0px 5px 15px 5px var(--ion-color-step-100);

  --primary-alert-background: #d4d8e5;
  --warning-alert-background: #fff3cd96;
  --brown-alert-background: #fdf7f2;

  --border-style: 0;
  // --ripple-color: grey;

  --ion-font-family: 'Poppins', sans-serif;
  --usage-unit: black;

  /** primary **/
  // --ion-color-primary: rgb(63 84 189);
  --ion-color-primary: #262a58;
  --ion-color-primary-rgb: 38, 42, 88;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #21254d;
  --ion-color-primary-tint: #3c3f69;
  /** secondary **/
  --ion-color-secondary: #b3e5fc;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #065a6f;
  --ion-color-tertiary-rgb: 6, 90, 111;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #065a6f;
  --ion-color-tertiary-tint: #065a6f;
  /** success **/
  --ion-color-success: #39995f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e7b20b;
  --ion-color-warning-light: #fffef6;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #ff5722;
  --ion-color-danger-rgb: 255, 87, 34;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf4d3c;
  --ion-color-danger-tint: #ed8157;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // @ CUSTOM COLORS @ //
  /** red **/
  --ion-color-red: #ff0000;
  --ion-color-red-rgb: 255, 0, 0;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #e00000;
  --ion-color-red-tint: #ff1a1a;

  .ion-color-red {
    --ion-color-base: var(--ion-color-red);
    --ion-color-base-rgb: var(--ion-color-red-rgb);
    --ion-color-contrast: var(--ion-color-red-contrast);
    --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
    --ion-color-shade: var(--ion-color-red-shade);
    --ion-color-tint: var(--ion-color-red-tint);
  }

  /** lightpurple **/
  --ion-color-lightpurple: #7caeff;
  --ion-color-lightpurple-rgb: 124, 174, 255;
  --ion-color-lightpurple-contrast: #000000;
  --ion-color-lightpurple-contrast-rgb: 0, 0, 0;
  --ion-color-lightpurple-shade: #6d99e0;
  --ion-color-lightpurple-tint: #89b6ff;

  .ion-color-lightpurple {
    --ion-color-base: var(--ion-color-lightpurple);
    --ion-color-base-rgb: var(--ion-color-lightpurple-rgb);
    --ion-color-contrast: var(--ion-color-lightpurple-contrast);
    --ion-color-contrast-rgb: var(--ion-color-lightpurple-contrast-rgb);
    --ion-color-shade: var(--ion-color-lightpurple-shade);
    --ion-color-tint: var(--ion-color-lightpurple-tint);
  }

  /** lightblue **/
  --ion-color-lightblue: #50c8ff;
  --ion-color-lightblue-rgb: 80, 200, 255;
  --ion-color-lightblue-contrast: #000000;
  --ion-color-lightblue-contrast-rgb: 0, 0, 0;
  --ion-color-lightblue-shade: #46b0e0;
  --ion-color-lightblue-tint: #62ceff;

  /** blue **/
  --ion-color-blue: #2e70d3;
  --ion-color-blue-rgb: 46, 112, 211;
  --ion-color-blue-contrast: #000000;
  --ion-color-blue-contrast-rgb: 0, 0, 0;
  --ion-color-blue-shade: #2766c4;
  --ion-color-blue-tint: #2e96d3;

  .ion-color-lightblue {
    --ion-color-base: var(--ion-color-lightblue);
    --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
    --ion-color-contrast: var(--ion-color-lightblue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
    --ion-color-shade: var(--ion-color-lightblue-shade);
    --ion-color-tint: var(--ion-color-lightblue-tint);
  }

  /** green **/
  --ion-color-green: #1b6f61;
  --ion-color-green-rgb: 27, 111, 97;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #186255;
  --ion-color-green-tint: #327d71;

  .ion-color-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
  }

  --ion-color-brown: #a54f00;
  --ion-color-brown-rgb: 165, 79, 0;
  --ion-color-brown-contrast: #ffffff;
  --ion-color-brown-contrast-rgb: 255, 255, 255;
  --ion-color-brown-shade: #914600;
  --ion-color-brown-tint: #ae611a;

  .ion-color-brown {
    --ion-color-base: var(--ion-color-brown);
    --ion-color-base-rgb: var(--ion-color-brown-rgb);
    --ion-color-contrast: var(--ion-color-brown-contrast);
    --ion-color-contrast-rgb: var(--ion-color-brown-contrast-rgb);
    --ion-color-shade: var(--ion-color-brown-shade);
    --ion-color-tint: var(--ion-color-brown-tint);
  }
}

body.dark {
  --ion-color-primary: #91d2eb;
  --ion-color-primary-rgb: 145, 210, 235;
  --ion-color-primary-contrast: #13192c;
  --ion-color-primary-contrast-rgb: 19, 25, 44;
  --ion-color-primary-shade: #8fb3eb;
  --ion-color-primary-tint: #5598ff;
  --usage-unit: white;
  --ion-color-secondary: #7a8ba9;
  --ion-color-secondary-rgb: 122, 139, 169;
  --ion-color-secondary-contrast: #13192c;
  --ion-color-secondary-contrast-rgb: 19, 25, 44;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #22a7c9;
  --ion-color-tertiary-rgb: 6, 90, 111;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3998af;
  --ion-color-tertiary-tint: #269ab8;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d1a109;
  --ion-color-warning-tint: #ffd948;
  --ion-color-warning-light: #201c0d;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #13192c;
  --ion-color-light-rgb: 19, 25, 44;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  /** green **/
  --ion-color-green: #2fdf75;
  --ion-color-green-rgb: 47, 223, 117;
  --ion-color-green-contrast: #000000;
  --ion-color-green-contrast-rgb: 0, 0, 0;
  --ion-color-green-shade: #29c467;
  --ion-color-green-tint: #44e283;

  --ion-color-brown: #d87f2c;
  --ion-color-brown-rgb: 165, 79, 0;
  --ion-color-brown-contrast: #ffffff;
  --ion-color-brown-contrast-rgb: 255, 255, 255;
  --ion-color-brown-shade: #b57438;
  --ion-color-brown-tint: #905f31;

  --brown-alert-background: #1f1d1a;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #0d1220;
  --ion-background-color-rgb: 13, 18, 32;

  --ion-text-color: #7a8ba9;
  --ion-text-note: #8092b1;
  --ion-text-color-rgb: 122, 139, 169;

  --ion-border-color: #222222;

  --primary-alert-background: var(--ion-color-primary-contrast);
  --warning-alert-background: #25231c;
  --disabled-primary-color: #686d84;
  --brown-alert-background: #1f1d1a;

  --card-box-shadow: 0;
  --border-box-shadow: 0;

  --ion-color-step-0: #3d4052;
  --ion-color-step-10: #212538;
  --ion-color-step-50: #13192c;
  --ion-color-step-100: #212538;
  --ion-color-step-150: #2f3345;
  --ion-color-step-200: #3d4052;
  --ion-color-step-250: #494c5d;
  --ion-color-step-300: #565968;
  --ion-color-step-350: #616372;
  --ion-color-step-400: #6c6e7c;
  --ion-color-step-450: #777986;
  --ion-color-step-500: #828490;
  --ion-color-step-550: #8d8f9a;
  --ion-color-step-600: #9b9da7;
  --ion-color-step-650: #aaabb3;
  --ion-color-step-700: #b8b9c0;
  --ion-color-step-750: #c9cacf;
  --ion-color-step-800: #dbdbdf;
  --ion-color-step-850: #ecedef;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  // --ion-item-background: #1e1e1e;

  // --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #13192c;

  --ion-card-background: #13192c;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
  --ion-item-background: var(--ion-color-step-150);
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #0d1220;
  --ion-background-color-rgb: 13, 18, 32;

  --ion-text-color: #7a8ba9;
  --ion-text-note: #8092b1;
  --ion-text-color-rgb: 122, 139, 169;

  --ion-border-color: #222222;

  --primary-alert-background: var(--ion-color-primary-contrast);
  --warning-alert-background: #25231c;
  --disabled-primary-color: #54596c;

  --card-box-shadow: 0;
  --border-box-shadow: 0;

  --ion-color-step-0: #3d4052;
  --ion-color-step-10: #0e1325;
  --ion-color-step-50: #13192c;
  --ion-color-step-100: #212538;
  --ion-color-step-150: #2f3345;
  --ion-color-step-200: #3d4052;
  --ion-color-step-250: #494c5d;
  --ion-color-step-300: #565968;
  --ion-color-step-350: #616372;
  --ion-color-step-400: #6c6e7c;
  --ion-color-step-450: #777986;
  --ion-color-step-500: #828490;
  --ion-color-step-550: #8d8f9a;
  --ion-color-step-600: #9b9da7;
  --ion-color-step-650: #aaabb3;
  --ion-color-step-700: #b8b9c0;
  --ion-color-step-750: #c9cacf;
  --ion-color-step-800: #dbdbdf;
  --ion-color-step-850: #ecedef;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  // --ion-item-background: #1e1e1e;

  // --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #13192c;

  --ion-card-background: #13192c;
}
